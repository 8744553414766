import { Layout, Row, Col } from "antd";
import React from "react";
import { MainTeaserProps } from "../../models/MainTeaserProps";
import Image from "../image/Image";
import Headline from "../headline/Headline";
import Text from "../text/Text";
import Button from "../button/Button";
import './welcome.css';
import Price from "../price/Price";
import ServiceList from '../list/ServiceList';
import { useMediaQuery } from "react-responsive";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const MainTeaser: React.FC<MainTeaserProps> = ({
  title,
  subtitle,
  text,
  mobileImageUrl,
  desktopImageUrl,
  button = "",
  serviceInfo = "",
  description,
  imageAlignmentTrailing,
  bulletPoints = [],
  discount = "",
  price = "",
  bookAction
}) => {

  const isBigScreen = useMediaQuery({ query: '(min-width: 992px)' })

  const priceInfo = () => {
    if (price) {
        return (<Price price={price} info={serviceInfo} />);
    }
    return (<></>);
  };

  const [primaryButtonText, ...restOfWords] = button.split(' ');
  const secondaryButtonText = restOfWords.join(' ');
  const [primaryTitle, ...secondaryTitleWords] = title.split(' ');
  const secondaryTitle = secondaryTitleWords.join(' ');

  function order() {
    return imageAlignmentTrailing ? 2 : 1;
  }

  const discountCircle = () => {
    return (
      <span className="ms-welcome__discount">
        {discount}
      </span>
    );
  };

  return (
    <Layout className="ms-inner-content ms-component ms-bottom--spacer">
        <Row className="ms-welcome" gutter={{ lg: 30, xl: 60 }} align='middle'>
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 12}}>
              <Row justify='space-between' align='middle'>
                <Col>
                  <Headline title={subtitle ? title : primaryTitle} subtitle={subtitle ? subtitle : secondaryTitle} level="h1" breaking={subtitle ? true : false} />
                </Col>
                <Col>
                  {discount && discountCircle()}
                </Col>
              </Row>
                {priceInfo()}
                <Text align="left" text={text} />
                {bulletPoints.length > 0 &&
                  <ServiceList items={bulletPoints}/>
                }
                {primaryButtonText &&
                  <Button
                  large={isBigScreen}
                  primaryText={primaryButtonText}
                  secondaryText={secondaryButtonText}
                  onClick={() => bookAction()}
                  />
                }
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12, order: order() }}>
                <Image mobileSrc={mobileImageUrl} desktopSrc={desktopImageUrl} alt={description} />
            </Col>
        </Row>
    </Layout>
  );
};

export default MainTeaser;
